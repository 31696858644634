import type { Collection, UserLikeVideoParams, UserLikeVideoRes, VideoDetail, VideoDetailRes, VideoPage } from './model'

import http from '~/utils/http'

// 添加用户收藏(观看)影片记录
export function setAddUserCollection(params: Collection) {
  return http.post<any>({
    url: '/video/home/addUserCollection',
    body: params
  })
}

// 取消用户(观看)收藏影片
export function setCanceUserCollection(params: Collection) {
  return http.post<any>({
    url: '/video/home/cancelUserCollection',
    body: params
  })
}

// 电影(电视剧/动漫/综艺)详情
export function getVideoDetail(params: VideoDetail) {
  return http.post<VideoDetailRes>({
    url: '/video/home/getVideoDetailsById',
    body: params
  })
}

// 猜你喜欢的视频列表
export function getUserLikeVideo(params: UserLikeVideoParams) {
  return http.post<UserLikeVideoRes>({
    url: '/video/home/getUserLikeVideoList',
    body: params
  })
}

// 电影(电视剧/动漫/综艺)分页列表
export function getVideoPage(params: VideoPage) {
  return http.post<any>({
    url: '/video/home/getVideoPage',
    body: params
  })
}
