<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    dialogClass?: string
    destroyOnClose?: boolean
    hasCloseIcon?: boolean
  }>(),
  {
    modelValue: false,
    destroyOnClose: true,
    hasCloseIcon: true
  }
)

const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  'after-open': [value?: unknown]
  'after-close': [value?: unknown]
  'before-open': [value?: unknown]
  'before-close': [value?: unknown]
  close: [value: boolean]
}>()

const transitionName = ref<string>('dialog-fade')
const elementId = ref<string | number>(0)
const contentRef = ref<HTMLElement | null>(null)

// 是否保持弹窗内容
const isKeepEle = computed(() => (props.destroyOnClose ? new Date().getTime() : elementId.value))

const beforeEnter = () => emits('before-open')

const beforeLeave = () => emits('before-close')

const afterEnter = () => emits('after-open')

const afterLeave = () => emits('after-close')

const close = () => emits('update:modelValue', false)

onClickOutside(contentRef, () => close())
</script>

<template>
  <transition
    :name="transitionName"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
    @before-enter="beforeEnter"
    @before-leave="beforeLeave"
  >
    <div
      v-if="modelValue"
      :key="isKeepEle"
      flex="~ col items-center justify-center"
      p-fixed-center
      overflow-hidden
      bg-00000090
      v-bind="$attrs"
    >
      <div ref="contentRef" :class="dialogClass" pos-relative min-h-30 min-w-30 b-rd-2 bg-111425>
        <BaseIcon v-if="hasCloseIcon" name="close" size="2.5" pos-absolute right-2 top-2 p-2 @click="close" />
        <slot />
      </div>
    </div>
  </transition>
</template>
