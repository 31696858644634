interface validateType {
  psd: (...args: any[]) => boolean
  userName: (...args: any[]) => boolean
  phone: (...args: any[]) => boolean
  phoneCode: (...args: any[]) => boolean
}

export function useValidate(): validateType {
  /**
   * 验证密码
   * 请输入8-12位，字母+数字组合的密码
   */
  function psd(psd: string) {
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/

    return reg.test(psd)
  }

  /**
   * 验证用户名
   * 长度4-11，包含字母+数字.
   */
  function userName(userName: string) {
    const reg = /^[a-zA-Z](?![a-zA-Z]+$)[0-9A-Za-z]{5,10}$/

    return reg.test(userName)
  }

  /**
   * 验证手机号
   * @param phone
   */
  function phone(phone: string) {
    const reg = /^[\d-]{8,16}$/
    // const reg = /^\d{8,13}$/

    return reg.test(phone)
  }

  /**
   * 验证码
   * @param phone
   */
  function phoneCode(phone: string) {
    const reg = /^\d{6}$/

    return reg.test(phone)
  }

  return {
    psd,
    userName,
    phone,
    phoneCode
  }
}
