<script setup lang="ts">
import type { InputValidate } from '~/types'

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholder?: string
    validate?: InputValidate[]
    change?: boolean
    blur?: boolean
    disabled?: boolean
    type?: string
    inputClass?: string
  }>(),
  {
    placeholder: '搜索您感兴趣的女优',
    change: true,
    type: 'text',
    blur: false,
    disabled: false
  }
)

const emits = defineEmits(['update:modelValue', 'keydown'])
// 错误信息
const errorMsg = ref<string>('')
// 是否聚焦
const isFocus = ref<boolean>(false)
// 密码显示标识
const passwordType = ref<boolean>(true)
// 计算input的类型
const calculateInputType = computed(() => {
  if (props.type === 'password') {
    return passwordType.value ? 'password' : 'text'
  }
  return props.type
})

// 验证处理
function handleValidate(e: InputEvent | any) {
  if (props.validate?.length) {
    for (let i = 0; i < props.validate.length; i++) {
      const rule = props.validate[i]
      if (rule.required && e.target?.value === '') {
        errorMsg.value = rule.message
        break
      }
      if (rule.validator && !rule.validator(e.target?.value)) {
        errorMsg.value = rule.message
        break
      }
      errorMsg.value = ''
    }
  }
}

// 输入事件
function handleInput(e: InputEvent | any) {
  emits('update:modelValue', e.target?.value)
  props.change && handleValidate(e)
}

// 聚焦事件
function handleFocus(e: InputEvent | any) {
  handleValidate(e)
  isFocus.value = true
}

// 失焦事件
function handleBlur(e: InputEvent | any) {
  props.blur && handleValidate(e)
  isFocus.value = false
}

// 失焦事件
function handleKeydown(e: KeyboardEvent | any) {
  emits('keydown', e)
}
</script>

<template>
  <div
    :class="[{ 'bl-324065': !!errorMsg, 'bg-1B213899': isFocus }, inputClass]"
    flex="~ items-start col"
    transition="~ colors 5"
    pos-relative
    w-full
    rd-5
    bg-1b2138
    bl-32406500
  >
    <div flex="~ items-center justify-start" h-10 w-full px-4>
      <slot name="prepend" />
      <input
        :value="modelValue"
        :disabled="disabled"
        :type="calculateInputType"
        transition="~ colors 5"
        :placeholder="placeholder"
        v-bind="$attrs"
        h-full
        w-full
        px-2
        text-sm
        outline-none
        :class="{ 'bg-1B213899': isFocus }"
        bg-1b2138
        maxlength="20"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
        @keydown="handleKeydown"
      />
      <div v-if="type === 'password'" cursor-pointer @click="passwordType = !passwordType">
        <p v-if="!passwordType" i-ep:view color="gray" />
        <p v-else i-ep:hide color="gray" />
      </div>
      <slot name="append" />
    </div>
    <p
      :class="!!errorMsg ? 'h-8' : 'h-0'"
      pos-absolute
      left-5
      top-full
      mt-1
      overflow-hidden
      font-size-3.5
      transition-height-200
      ct-ff2020
    >
      {{ errorMsg }}
    </p>
  </div>
</template>

<style>
/* Safari，Chrome WebKit browsers */
input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.2;
}
/* 火狐 Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.2;
}
/* 火狐 Mozilla Firefox 19+ */
input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.2;
}
/* Internet Explorer 10+ */
input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.2;
}
</style>
